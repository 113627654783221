<template>
  <div class="container">
    <div class="row">
        <div class="col-sm-2"><b>Attribut</b></div>
        <div class="col-sm-2"><b>Art der Änderung</b></div>
        <div class="col-sm-2"><b>Alter Text</b></div>
        <div class="col-sm-2"><b>Neuer Text</b></div>
     </div>
      <div class="row" v-for="row in textData" :key="row.id">
            <div class="col-sm-2"> {{row.attribute}}</div>
            <div class="col-sm-2"> {{row.modificationType}}</div>
            <div class="col-sm-2"> {{row.oldText}}</div>
            <div class="col-sm-2"> {{row.newText}}</div>
       </div>
  </div>
</template>

<script>
//import axios from "axios";
//import TextItemsRow from './TextItemsRow.vue'
//import OpenCloseToggle from './OpenCloseToggle.vue'

export default {
  name: 'TextItemsList',
  components: {
      //TextItemsRow,
      // OpenCloseToggle
  },
  data() {
    return {
      loading: true,
      requestItems: null,
      error: "",
      isOpen: false,
      item: null,
      i: 0
    }
  },
  props: 
  {
      requestId : 
         {
           type:Number,
           default: 0
         },
      comment: String,
      competences: String,
      trigger_prop : Boolean,
      textData : Object
  },
  watch: 
    {
      requestId: function(newVal, oldVal){
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    }
  }
};
</script>
<style scoped>
.TextItemsList {
    padding-left: 10px;
    margin-left: 10px;
}
</style>

