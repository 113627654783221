<template>
  <div class="row">
    <div class="col-sm-2"> {{ rowData.expert.name}}</div>
    <div class="col-sm-2"> {{ rowData.ratingResult }}</div>
    <div class="col-sm-1"> {{ rowData.expertComment }}</div>
    <div v-if="this.rowData.expert.id==this.$store.state.user.userId">
      <label for="comment">neuer Kommentar:</label>&nbsp;&nbsp;
      <input id="comment" type="text" width=100 maxlength="100" v-model="comment">&nbsp;&nbsp;
      <label for="approve">Zustimmen:</label>&nbsp;&nbsp;
      <input type="checkbox" id ="approve" name = "evaluate" v-model="approved" >&nbsp;
      <button color="primary" type="filled" @click="onButtonClick()">Bewerten / Bewertung ändern</button>
    </div>
    <!--
    <button
      type="button"
      class="btn"
      @click="showModal"
    >
      Open Modal!
    </button>
    -->
    <ModalDlg
      v-show="isModalVisible"
      @close="closeModal">
       <template v-slot:header>
      Bewerten der Anfrage: {{ rowData.id}}
      </template>

      <template v-slot:body>
      <input type="text" v-model="val1">
      <input type="text" v-model="val2">
      </template>
    </ModalDlg>
  </div>
</template>

<script>
import axios from "axios"
import ModalDlg from './ModalDlg.vue'
export default {
  name: 'RatingsRow',
    components: {
      ModalDlg,
      // OpenCloseToggle
  },
  data() {
    return {
      requestItems: null,
      error: "",
      isOpen: false,
      item: null,
      isModalVisible: false,
      val1: "Vorgabe val1",
      val2: "Vorgabe val2",
      approved: false,
      approved_prev: false,
      comment: "",
      comment_prev: "",
      i: 0
    }
  },
  mounted: function() 
  {
    {
      if (this.rowData.expertComment == (null || ""))
      {
        this.rowData.expertComment ="N/A";
        this.comment= "";
        this.comment_prev = this.comment;
      }
      else
      {
        this.comment=this.rowData.expertComment;
      }
      this.approved = this.rowData.ratingResult === 'ACCEPTED';
      this.approved_prev = this.approved;
    }
  },
  props: 
  {
    rowData : Object
  },
    methods: {
      onButtonClick() 
      {
        if (!this.approved && this.comment == (null || ""))
        {
          alert("Bei Ablehnung muss ein Kommentar abgegeben werden!");
          return;
        }
        if (!(this.comment === this.comment_prev) || this.approved != this.approved_prev)
        {
          var headers_list = {
            'Content-Type' : 'application/json', 
            'Authorization' : this.$store.state.user.authString
          }
          var expertRatingDto= {
            'ratingId' : this.rowData.id,
            'comment' : this.comment,
            'isAccepted' : this.approved,
            'refreshToken' : this.$store.state.user.refreshToken
          }
          axios
            .post(this.$store.state.qm_url + 'update_rating', expertRatingDto, {headers: headers_list})
            .then(() => {
              this.loading = false;
              this.$store.state.refreshCounter++;
          })
          .catch((err) => {
            alert("Update der Bewertung schlug fehl: " + err);
            this.error = err;
          });
        }
        else
        {
          alert("Es wurde keine Änderung in der Bewertung vorgenommen!");
        }
      },
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
        alert(this.val1);
        alert(this.val2);
      }
  } 
}
</script>

