<template>
    <div id="login">
        <h1>Anmeldung zum Qualitäts-Management des ASI Merkmal-Servers</h1>
        <input type="text" name="username" v-model="input.username" placeholder="Username" />
        <input type="password" name="password" v-model="input.password" placeholder="Password" />
        <button type="button" v-on:click="login()">Anmeldung</button>
        <h4> {{error}} </h4>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: 'Login',
        data() {
            return {
                input: {
                    username: "",
                    password: ""
                },
                loading : false,
                error: "Benutzername und Password eingeben",
                resp : null,
                errmsg : "",
            }
        },
        methods: {
            login() 
            {
                if(this.input.username != "" && this.input.password != "") 
                {
                    var headersList = {
                        'Content-Type': 'application/json',
                    }
                    let loginInfo = {
                    "username": this.input.username, 
                    "password": this.input.password, 
                    };
                    var url=this.$store.state.qm_url;
                    if (url.includes("localhost"))
                        url= url + "login";
                    else
                        url= url + "/login";
                    axios
                    .post(url, loginInfo, {headers: headersList})
                    .then((response) => {
                        this.resp = response.data;
                        if (this.resp.status == 'OK')
                        {
                            this.$emit("authenticated", true);
                            this.$router.replace({ name: "secure" });
                            this.$store.commit("setState", this.resp);
                        }
                        else
                        {
                            this.error="Benutzername oder Password nicht korrekt!"
                        }
                    })
                    .catch((err) => {
                        this.error = "Fehler bei der Anmeldung";
                        this.errmsg = err;
                    })
                } else {
                    console.log("A username and password must be present");
                    this.error="Benutzername und Password müssen eingegeben werden!"
                }
            }
        }
    }
</script>

<style scoped>
    #login {
        width: 500px;
        border: 1px solid #CCCCCC;
        background-color: #FFFFFF;
        margin: auto;
        margin-top: 200px;
        padding: 20px;
    }
</style>