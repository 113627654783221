<template>
    <div id="app">
        <div id="nav">
            <router-link v-if="authenticated" to="/login" v-on:click.native="logout()" replace>Logout</router-link>
            &nbsp;&nbsp;<a v-if="authenticated" v-on:click="reload()">Reload</a>
            &nbsp;&nbsp;<a v-if="authenticated && $store.getters.isAdmin" v-on:click="admin()">Administration</a>
        </div>
        <router-view @authenticated="setAuthenticated" />
    </div>
</template>

<script>
    export default {
        name: 'App',
        data() {
            return {
                authenticated: false,
                mockAccount: {
                    username: "nraboy",
                    password: "password"
                }
            }
        },
        mounted() {
            if(!this.authenticated) {
                this.$router.replace({ name: "login" });
            }
        },
        methods: {
            setAuthenticated(status) {
                this.authenticated = status;
            },
            logout() {
                this.authenticated = false;
            },
            reload() {
                this.$store.state.refreshCounter++;
            },
            admin() {
                alert('Admin noch nicht implementiert')
            }
        }
    }
</script>

<style>
    body {
        background-color: #F0F0F0;
    }
    h1 {
        padding: 0;
        margin-top: 0;
    }
    #app {
        width: 100%;
        margin: 20px;
    }
  .div-table {
      display: table;         
      width: auto;         
      background-color: #eee;         
      border: 1px solid #666666;         
      border-spacing: 5px; /* cellspacing:poor IE support for  this */
      empty-cells: show;
    }
    .div-table-row {
      display: table-row;
      width: auto;
      clear: both;
      margin-bottom: 10px;
    }
    .div-table-col {
      float: left; /* fix for  buggy browsers */
      display: table-column;         
      width: 200px;         
      background-color: #ccc;  
    }
    .div-table-cell
    {
        float:left;/*fix for  buggy browsers*/
        display:table-column;
        width:200px;
        background-color:#ccc;
    }
    .div-table-expand
    {
        float:left;/*fix for  buggy browsers*/
        display:table-column;
        width:15px;
        background-color:#ccc;
    }
    .table_border
    {
        border: 1px solid #666666;         
    }
</style>