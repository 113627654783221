<template>
  <div class="container">
    <p v-if="loading">Loading rating items ...</p>
    <div v-else>
      <div class="row">
        <div class="col-sm-2"><b>Experte</b></div>
        <div class="col-sm-2"><b>Status</b></div>
        <div class="col-sm-1"><b>Kommentar</b></div>
      </div>
        <div class="row" v-for="(row, index) in ratingItems" :key="row.id">
          <RatingsRow v-bind:rowData="ratingItems[index]"/> 
        </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RatingsRow from './RatingsRow.vue'
//import OpenCloseToggle from './OpenCloseToggle.vue'

export default {
  name: 'RatingsList',
  components: {
      RatingsRow,
      // OpenCloseToggle
  },
  data() {
    return {
      loading: true,
      ratingItems: null,
      error: "",
      isOpen: false,
      item: null,
      i: 0
    }
  },
  created: function() 
  {
    {
      var headers_list = {
        'Content-Type' : 'application/json', 
        'Authorization' : this.$store.state.user.authString
      }
      var parameters_list = {
        'itemId' : this.itemId
      }
      this.loading = true;
      axios
        .get(this.$store.state.qm_url + 'rating_items', {params: parameters_list, headers: headers_list})
        .then((res) => {
          this.loading = false;
          this.ratingItems = res.data;
       })
        .catch((err) => {
          this.loading = false;
          this.error = err;
        });
    }
  },
  props: 
  {
      itemId : 
         {
           type:Number,
           default: 0
         },
  },

};
</script>
<style scoped>
.container {
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}
</style>

