<style scoped>
.X:before  {
      content: "\f063";         
    }
.Y:before  {
      content: ">";         
    }
.open_close_toggle {
      background-color:rgb(255, 255, 255);         
    }
.fa-chevron-down {
    content: "Z";
}
</style>
<template>
 <span v-bind:class="{ 'fas fa-angle-down' : isOpen, 'fas fa-angle-right' : !isOpen }"  
			@click="toggle">
</span>
</template>
<script>
export default {
    name: 'OpenCloseToggle',
    props : {
			doEmit : { type : Boolean, default : true }
    },
    computed : {
        isOpen : function () {
            return this.$parent.isOpen;
        },

    },
    methods : {
        toggle : function () {
            this.$parent.isOpen = !this.$parent.isOpen;
        }
    },
}
</script>
