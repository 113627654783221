// store/index.js
 
import Vue from "vue";
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
     user: {
         name : '',
         qmRoleEnum : 0,
         userId : 0,
         username : '',
         authString : '',
         refreshToken : ''
     },
     //qm_url: 'http://localhost:8080/qm_db/',
     qm_url: 'https://dbis-ms-qm.uibk.ac.at/api/qm_db/',
     refreshCounter : 0
 },
 getters: {
    roleName: state => {
        switch (state.user.qmRoleEnum) {
            case 0: 
                return 'Contributor';
            case 1: 
                return 'Experte';
            case 2:
                return 'Administrator';
        }
        
    },
    isExpert : state => {
        if (state.user.qmRoleEnum == 1)
            return true;
        else
            return false;
    },
    isUser : state => {
        if (state.user.qmRoleEnum == 0)
            return true;
        else
            return false;
    },
    isAdmin : state => {
        if (state.user.qmRoleEnum == 2)
            return true;
        else
            return false;
    }
 },
 mutations: {
     setState(state, msUser)
     {
         state.user.name = msUser.name;
         state.user.qmRoleEnum = msUser.qmRole;
         state.user.authString = 'bearer ' + msUser.token;
         state.user.refreshToken = msUser.refreshToken;
         state.user.userId = msUser.userId;
     }
 },
 actions: {}
});