<template>
<div>
    <div class="col-sm-2"> {{ rowData.domainCompetenceRequired.name }}</div>
    <div class="col-sm-2"> {{ rowData.stadardizationCompetence.name }}</div>
    <div class="col-sm-1"> {{ rowData.numExpertRatings }}</div>
    <div v-if="itemType==='Text'">
        <span>Sprache: {{language}}</span>
        <TextItemsList v-bind:textData="rowData.textChangeItems"/>
    </div> 
    <div v-else-if="itemType==='Parent'">
        <br>
        <ParentItemsList v-bind:parentData="rowData.parentRelations"/>
    </div> 
    <span class="col-sm-1" v-if="hasRatings">
      <OpenCloseToggle class= "rot"/>
    </span>  
    <span class="col-sm-1" v-else><DisabledToggle/></span>
 <div v-if="isOpen"><br><br v-if="isLastLine">
    <RatingsList v-bind:itemId="rowData.id"/>
 </div>
</div>
</template>
<script>
import OpenCloseToggle from './OpenCloseToggle.vue'
import DisabledToggle from './DisabledToggle.vue'
import TextItemsList from './TextItemsList.vue'
import ParentItemsList from './ParentItemsList.vue'
import RatingsList from './RatingsList.vue'

export default {
    name: 'ItemsRow',
    components: 
    { 
        OpenCloseToggle,
        DisabledToggle,
        TextItemsList,
        ParentItemsList,
        RatingsList
    },
    data()  {
        return {
            isOpen: false,
            data : null,
            itemType : 'General',
            language : '',
        };
    },
      created: function() 
    {
        {
            this.data= this.rowData;
            //alert(this.data.domainCompetenceRequired.aoc.aocClassification)
            if (this.data.domainCompetenceRequired == null)
            {
                this.data.domainCompetenceRequired = 
                {
                    'name' : ''
                }
            }            
            if (this.data.stadardizationCompetence == null)
            {
                if (this.data.domainCompetenceRequired == null)
                {
                    this.data.stadardizationCompetence = 
                    {
                        'name' : ''
                    }
                }
                else
                {
                    if (this.data.domainCompetenceRequired.aoc.aocClassification == 'DOMAIN')
                    {
                        this.data.stadardizationCompetence = 
                        {
                            'name' : 'Text-Überprüfung'
                        }
                 
                    }
                    else
                    this.data.stadardizationCompetence = 
                    {
                        'name' : ''
                    }
                }
            }
            switch (this.rowData.itemType)
            {
                case 0:
                    this.itemType= 'General';
                    break;
                case 1:
                    this.itemType = 'Text';
                    this.language = this.data.languageCode;
                    break;
                case 2:
                    this.itemType = 'Relation';
                    break;
                case 3:
                    this.itemType = 'Parent'
                    break;

            }

        }
    },
    computed : {
        hasRatings : function () {
            return this.rowData.numExpertRatings > 0
        },
    },

    props: {
        rowData : Object,
        isLastLine: Boolean,
    },

}
</script>
<style scoped>
.container {
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}
.rot
{
    color:red;
}
</style>
