<template>
  <div>
  <p v-if="loading">Loading requests ...</p>
  <div class="container-fluid padding" v-else>
    <div class="row">
      <span class="col-xs-1">
      <i class="fas fa-square"></i>
      </span>  
      <div class="col-xs-2"><strong>Änderungs-Typ</strong></div>
      <div class="col-xs-2"><strong>Anwender</strong></div>
      <div class="col-xs-2"><strong>Konzept-Typ</strong></div>
      <div class="col-xs-2"><strong>Konzept-Name</strong></div>
      <div class="col-xs-1"><strong>Eingelangt</strong></div>
      <div class="col-xs-1"><strong>Status</strong></div>
    </div>
    <div class= "row" v-for="(row, index) in ratings" :key="row.id">
      <RequestRow v-bind:rowData="ratings[index]" v-bind:isLastLine="index == (ratings.length-1)"/> 
    </div>
    <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RequestRow from './RequestRow.vue'
//import OpenCloseToggle from './OpenCloseToggle.vue'

export default {
  name: 'RequestsList',
  components: {
      RequestRow,
      // OpenCloseToggle
  },
  data() {
    return {
      loading: false,
      ratings: null,
      error: "",
      isOpen: false,
    }
  },
  created: function() {
    this.loadData();
  },
  methods: {
    loadData()
    {
    var headers_list = {
      'Content-Type' : 'application/json', 
      'Authorization' : this.$store.state.user.authString
    }
    this.loading = true;
    axios
      .get(this.$store.state.qm_url + '/active_requests', {headers: headers_list})
      .then((res) => {
        this.loading = false;
        this.ratings = res.data;
      })
      .catch((err) => {
        this.loading = false;
        this.error = err;
      });
    }
  },
  computed: {
    refreshCounter() {
      return this.$store.state.refreshCounter;
    },
  },
  watch: {
    refreshCounter() {
      alert('reloading Data');
      this.loadData();
    }
  },
};
</script>
<style scoped>
.container {
     padding-right: 12px;
    padding-left: 100px;
    margin-right: auto;
    margin-left: auto;
}
</style>
