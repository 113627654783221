<style scoped>
.X:before  {
      content: "\f063";         
    }
.Y:before  {
      content: ">";         
    }
.open_close_toggle {
      background-color:rgb(255, 255, 255);         
    }
.fa-chevron-down {
    content: "Z";
}
</style>
<template>
 <span class= 'greyed fas fa-angle-right'>
</span>
</template>
<script>
export default {
    name: 'DisabledToggle',
}
</script>
<style scoped>
.greyed {
    color: lightgray;
}

</style>
