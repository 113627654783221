<template>
    <div id="Secure">
        <h3 align = "left">
            QM: Angemeldeter Benutzer: {{this.$store.state.user.name}} ({{this.$store.getters.roleName}})
        </h3>
        <RequestsList v-if="$store.getters.isAdmin"></RequestsList>
        <RequestsList v-else-if="$store.getters.isExpert"></RequestsList>
        <p v-else> Is User </p>
    </div>

</template>

<script>
    import RequestsList from './RequestsList.vue'
    //import ExpertDisplay from './ExpertDisplay.vue'
    export default {
        name: 'Secure',
        components: {
            RequestsList
        },
        data() {
            return {};
        }
    }

</script>
<style scoped>
    #secure {
        background-color: #FFFFFF;
        border: px solid #CCCCCC;
        padding: 20px;
        margin-top: 10px;
    }
</style>
