<template>
<div>
    <span class="col-xs-1">
        <OpenCloseToggle></OpenCloseToggle>
    </span>  
    <div class="col-xs-2"> {{ rowData.modificationType }}</div>
    <div class="col-xs-2"> {{ rowData.userName }}</div>
    <div class="col-xs-2"> {{ rowData.conceptType }}</div>
    <div class="col-xs-2"> {{ rowData.conceptName }}</div>
    <div class="col-xs-1"> {{ rowData.submissionDate }}</div>
    <div class="col-xs-1"> {{ rowData.status }}</div>
    <div v-if="isOpen"><br><br v-if="isLastLine">
        <ItemsList v-bind:requestId="rowData.id" v-bind:comment="rowData.userComment" v-bind:trigger_prop="trigger" v-bind:competences="compsString"/>
    </div>
</div>
</template>
<script>
import OpenCloseToggle from './OpenCloseToggle.vue'
import ItemsList from './ItemsList.vue'

export default {
    name: 'RequestsRow',
    components: 
    { 
        OpenCloseToggle,
        ItemsList,
    },
    data()  {
        return {
            isOpen: false,
            trigger: false,
            compsString : 'someComp',
            type : 'B',
        };
    },
    created: function() {
            var count=0;
            var numEntries = this.rowData.competencesAssigned.length;
            this.compsString= '';
            for (var comp of this.rowData.competencesAssigned)
            {
               this.compsString+= comp.name;
               if (count < numEntries - 1)
                    this.compsString+= ', ';
                count++;

            }
            if (this.itemType == 0)
               this.type = 'A'
            else if (this.itemType == 1)
                this.type = 'B'
            else if (this.itemType == 3)
                this.type = 'C';
    },
//        computed : {
//        type : function () {
//            return this.rowData.itemType;
//        },
//    },
    props: ['rowData', 'isLastLine'],
}
</script>
<style scoped>
</style>
