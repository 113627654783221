<template>
  <div class="container">
    <p v-if="loading">Loading request items ...</p>
    <div v-else>
      <p>Anwender Kommentar: {{comment}} , zugeordnete Kompetenzen: {{competences}}</p>
      <div class="row">
        <div class="col-sm-2"><b>Fach-Kompetenz</b></div>
        <div class="col-sm-2"><b>Normungs-Kompetenz</b></div>
        <div class="col-sm-1"><b>Bewertungen</b></div>
      </div>
        <div class="row" v-for="(row, index) in requestItems" :key="row.id">
          <ItemsRow v-bind:rowData="requestItems[index]"/> 
        </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ItemsRow from './ItemsRow.vue'
//import OpenCloseToggle from './OpenCloseToggle.vue'

export default {
  name: 'ItemsList',
  components: {
      ItemsRow,
      // OpenCloseToggle
  },
  data() {
    return {
      loading: true,
      requestItems: null,
      error: "",
      isOpen: false,
      item: null,
      i: 0
    }
  },
  created: function() 
  {
    {
      var headers_list = {
        'Content-Type' : 'application/json', 
        'Authorization' : this.$store.state.user.authString
      }
      var parameters_list = {
        'requestId' : this.requestId
      }
      this.loading = true;
      axios
        .get(this.$store.state.qm_url + 'change_items', {params: parameters_list, headers: headers_list})
        .then((res) => {
          this.loading = false;
          this.requestItems = res.data;
       })
        .catch((err) => {
          this.loading = false;
          this.error = err;
        });
    }
  },
  props: 
  {
      requestId : 
         {
           type:Number,
           default: 0
         },
      comment: String,
      competences: String,
      trigger_prop : Boolean
  },
  watch: 
    {
      requestId: function(newVal, oldVal){
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    }
  }    

};
</script>
<style scoped>
.container {
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}
</style>

